import { Box, Divider, Typography } from '@mui/material';
import * as React from 'react';
import scss from '../../../AutomationPage.module.scss'

type Props = {
  children: React.ReactElement;
  title: string;
  width?: string;
  isBoxShadow?: boolean;
  offDevider?: boolean;
}

export const SettingsWrapper = ({ children, title, width, isBoxShadow, offDevider }: Props) => {
  return (
    <Box className={scss.setting__mainSetting}>
      <Typography className={scss.setting__title}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

