import { styled, TextField } from "@mui/material";

export const StyledTextFieldPromptNameInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '45px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    margin: 0,
    backgroundColor: 'white',

    '&:hover fieldset': {
      borderColor: '#c7d1e5',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 0px 2px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})