import * as React from 'react';
import scss from '../TableTeamManagement.module.scss';
import { Avatar, Box, CircularProgress, IconButton, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTableTeamManagementData } from '../../../utils/redux/storeHooks/dataHooks/useTableTeamManagementData';
import { useTableTeamManagementActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableTeamManagementActions';
import { TableLoader } from '../../tableLoader/TableLoader';

export const TableTeamManagmentBody = () => {
  const {
    filteredTeamMembers,
    loading,
    organizationRole,
    deletingRowId
  } = useTableTeamManagementData()
  const {
    deleteTeamMemberAndUpdateTeamList,
  } = useTableTeamManagementActions()

  const deleteMember = async (selectedRowId: number | string) => {
    deleteTeamMemberAndUpdateTeamList({ selectedRowId })
  };

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {loading && filteredTeamMembers?.length > 0 && <TableLoader />}
      {filteredTeamMembers?.map((row) => {
        const profileUrl = row.profileUrl.toString() === 'none' ? '' : row.profileUrl.toString()
        return (
          <TableRow
            hover
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              maxHeight: '64px',
            }}
          >
            <TableCell sx={{ padding: 0, position: 'sticky', left: 0, }}>
              <Box sx={{ maxWidth: 250, minWidth: 250, padding: '12px 12px', background: 'white', boxShadow: '1px 0px 2px 0px rgba(0,0,0,0.15)' }}>
                <Box className={scss.profileWrapper}>
                  <a
                    className={scss.firstLastNameText}
                    href={profileUrl}
                    target='_blank'
                    rel="noreferrer"
                    style={{ pointerEvents: profileUrl ? 'auto' : 'none' }}
                  >
                    <Stack direction="row">
                      <Avatar
                        alt=''
                        src={row.profileImg}
                        sx={{ width: '40px', height: '40px' }}
                      />
                    </Stack>
                  </a>
                  <Box className={scss.userInfo} sx={{ marginLeft: '12px' }}>
                    <Box
                      className={scss.firstLastNameText}
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      <a
                        className={scss.firstLastNameText}
                        href={profileUrl}
                        target='_blank'
                        rel="noreferrer"
                        style={{ pointerEvents: profileUrl ? 'auto' : 'none' }}
                      >
                        <Typography className={scss.firstLastNameText}>
                          {row.firstName}
                        </Typography>
                        <Typography className={scss.firstLastNameText}>
                          {row.lastName}
                        </Typography>
                      </a>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.memberEngagement}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.messagesSent}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.profileViews}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.likes}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.replies}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.pendingReplies}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.totalLeadCount}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.totalFeedCount}</TableCell>
            <TableCell sx={{ padding: '0px 12px' }} align="left">{row.role}</TableCell>
            <TableCell
              sx={{
                padding: '0px 12px',
                overflow: 'hidden'
              }}>
              {row.company.length > 16 ? `${row.company.slice(0, 16)}...` : row.company}
            </TableCell>
            {organizationRole !== 'member' && (
              <TableCell sx={{ padding: '0px 12px' }} key={row.id} align="left">
                <Box sx={{
                  height: 40,
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  {deletingRowId === row.id
                    ? <CircularProgress
                      size={26}
                      color='inherit'
                    />
                    : <IconButton color="primary" aria-label="dm"
                      onClick={() => deleteMember(row.id)}
                    >
                      <img alt="" src={'./remove_member.svg'} />
                    </IconButton>
                  }
                </Box>
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  )
}