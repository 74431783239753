import { Avatar, Box, Button, CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { useEffect, useRef, useState } from 'react';
import { getUserInformationFromlocalStorage } from '../../../../utils/general/get_set_user_information';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';

type Props = {
  postId: number;
  userIdentity: string;
  isHidden: boolean;
}

export const PostComment = ({ postId, userIdentity, isHidden }: Props) => {
  const { promptList, generatedMessages, postIdsSendingMessage, postIdGeneratedMessage } = useNewFeedData()
  const currentMessage = generatedMessages.find(message => message.postId === postId);
  const [value, setValue] = useState(currentMessage?.text || '');
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userInformation = getUserInformationFromlocalStorage()
  const { generateMessage, sendMessage } = useNewFeedActions();
  const textarea = useRef(null)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleOpenPrompts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hadleGenerateMessage = (id: number) => {
    generateMessage({
      isRequestFromExtension: false,
      promptId: id,
      feedId: postId,
    })
    setAnchorEl(null);
  }

  const hadleSendMessage = () => {
    sendMessage({
      leadIdentity: userIdentity,
      id: Number(postId),
      message: value
    })
  }

  useEffect(() => {
    setValue(currentMessage?.text)
    const { current } = textarea;
    if (current) {
      setTextareaHeight(`${current.scrollHeight + 4}px`)
    }
  }, [currentMessage])

  return (
    <Box className={style.post__comment}>
      <Avatar
        alt={userInformation.firstName}
        src={userInformation.profileImg}
        sx={{ width: 40, height: 40 }}
      />
      <Box sx={{ width: '600px' }}>
        <textarea
          ref={textarea}
          style={{ height: textareaHeight }}
          placeholder="Write something..."
          value={value}
          onChange={handleChange}
          className={style.post__comment_textarea}
          rows={5}
          disabled={isHidden}
        />
        <Box className={style.post__comment_actions}>
          <Typography className={style.post__comment_actions_info}>
            *your reply will be sent directly to LinkedIn
          </Typography>
          <Box className={style.post__comment_actions_wrapper}>
            {postIdGeneratedMessage.includes(postId) ? (
              <Box className={style.post__comment_actions_loaderWrapper}>
                <CircularProgress sx={{ color: '#3B2470' }} size={28} />
              </Box>
            ) : (
              <Button onClick={handleOpenPrompts} disabled={isHidden}>
                <img alt="generate prompt" src={'/heet_button.svg'} />
              </Button>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  borderRadius: 1,
                  width: 300,
                },
              }}
            >
              {promptList.map((prompt) => (
                <MenuItem
                  sx={{
                    borderBottom: '1px solid #DDDFE3',
                    fontFamily: 'Kanit',
                    fontWeight: 300,
                    margin: '8px'
                  }}
                  key={prompt.id}
                  onClick={() => hadleGenerateMessage(prompt.id)}
                >
                  {prompt.name}
                </MenuItem>
              ))}
            </Menu>
            <MainActionButton
              isDisabled={isHidden || !value}
              title={isHidden ? 'Already sent' : 'Reply'}
              width={isHidden ? 150 : 50}
              height={35}
              borderRadius='2px'
              fontWeight={300}
              actionOnClick={hadleSendMessage}
              isLoading={postIdsSendingMessage.includes(postId)}
            />
          </Box>
        </Box>
      </Box>

    </Box >
  )
}