import { Box, Typography } from "@mui/material"
import * as React from 'react';
import scss from '../../../AutomationPage.module.scss'
import { PromptV2 } from "../../../../../types/entities/promptV2";
import { useAutomationActions } from "../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions";

type Props = {
  currentPrompt: PromptV2;
}

export const PromptBody = ({ currentPrompt }: Props) => {
  const { setTextareaPrompt } = useAutomationActions()

  const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaPrompt({
      inputValue: event.target.value,
      currentPromptLink: currentPrompt.linkName
    });
  };
  return (
    <Box className={scss.promptSetting__prompt}>
      <Typography className={scss.promptSetting__sectionTitle}>
        Prompt
      </Typography>
      <textarea
        placeholder="Write prompt"
        value={currentPrompt.prompt}
        onChange={handleChangeTextArea}
        className={scss.promptSetting__textarea}
        rows={5}
      />
    </Box>
  )
}

