import { getRequest, postRequest } from "../../../general/https"
import { DeletePromptPayload, DeletePromptResponse, GetAutomationConfigResponse, GetPromptsResponse, RunAutomationPayload, RunAutomationResponse, SaveNewPromptPayload, SaveNewPromptResponse, UpdateConfigPayload, UpdateIsGenerateMessagePayload, UpdateIsGenerateMessageResponse, UpdatePromptPayload, UpdatePromptResponse, getLeadsToExcludePayload, getLeadsToExcludeResponse } from "./types"
import { checkErrorStatus } from "../../../general/checkErrorStatus"
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition"

export const runAutomation = createAsyncThunkCheckSubscription<RunAutomationResponse, RunAutomationPayload>(
  'automationPageSlice/runAutomation',
  async (payload, {rejectWithValue}) => {
    try {
      const {tab, isAutomationRunning, excludeLeads, promptId} = payload;
      const body = {isAutomationRunning, tabId: tab.id, excludeLeads, promptId};
      const response = await postRequest('/automation/run-automation', body ,true);
      checkErrorStatus(response)

      if(response.data.status === 'canceling') {
        throw new Error(response.data.status)
      }

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getAutomationConfig = createAsyncThunkCheckSubscription<GetAutomationConfigResponse>(
  'automationPageSlice/getAutomationConfig',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest('/automation/get-automation-config', true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateConfig = createAsyncThunkCheckSubscription<GetAutomationConfigResponse, UpdateConfigPayload>(
  'automationPageSlice/updateConfig',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/automation/update-automation-config', body, true)
      checkErrorStatus(response)

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getLeadsToExclude = createAsyncThunkCheckSubscription<getLeadsToExcludeResponse, getLeadsToExcludePayload>(
  'automationPageSlice/getLeadsToExclude',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/lead-information/get-leads-to-exclude-automation', body, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getAllPrompts = createAsyncThunkCheckSubscription<GetPromptsResponse>(
  'automationPageSlice/getAllPrompts',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest('/prompt/get-all-prompts-v2', true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateIsGenerateMessage = createAsyncThunkCheckSubscription<UpdateIsGenerateMessageResponse, UpdateIsGenerateMessagePayload>(
  'automationPageSlice/updateIsGenerateMessage',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest('/automation/update-is-message-generate', payload, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const saveNewPrompt = createAsyncThunkCheckSubscription<SaveNewPromptResponse, SaveNewPromptPayload>(
  'automationPageSlice/saveNewPrompt',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/prompt/create-prompt-for-user-v2', body, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updatePrompt = createAsyncThunkCheckSubscription<UpdatePromptResponse, UpdatePromptPayload>(
  'automationPageSlice/updatePrompt',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/prompt/update-prompt-for-user-v2', body, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deletePrompt = createAsyncThunkCheckSubscription<DeletePromptResponse, DeletePromptPayload>(
  'automationPageSlice/deletePrompt',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/prompt/delete-prompt-for-user-v2', body, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
