import React from 'react';
import styles from "./BlockingModal.module.scss";
import { Box, Typography, Zoom } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CallIcon from '@mui/icons-material/Call';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { TooltipApp } from '../../tooltipApp/TooltipApp';
import { Link } from 'react-router-dom';

export const PopUpPPGSubscriptionEnd = () => {
  const { sendEmailToChangePaymentDetails, cancelPlan } = useAccountDetailsActions();
  const { isRequestToSendEmailUpdatePaymentDetails, activeSubscription, isCancelPlanRequest, } = useAccountDetailsData()

  // const onRequestCall = () => {
  //   window.open(`https://meetings-eu1.hubspot.com/michael-pihosh/heet`, '_blank');
  // }

  const onSendEmail = async () => {
    await sendEmailToChangePaymentDetails({
      customerEmail: getUserInformationFromlocalStorage().email
    })
  }

  // const onCancelPlan = () => {
  //   cancelPlan({
  //     subscriptionId: activeSubscription.id,
  //     reasonText: 'Manual terminate subscription'
  //   })
  // }

  return (
    <Box className={`${styles.popup} ${styles.popup__ppgSubscriptionEnd}`}>
      <Box className={styles.header}>
        <img src='/subscriptoin_lock.svg' alt='lock' className={styles.header__img} />
        <Typography className={styles.header__title}>
          💎 Don’t lose your progress — update your payment to continue subscription
        </Typography>
        <Typography className={`${styles.header__subTitle} ${styles.header__subTitle__ppgSubscriptionEnd}`}>
          We couldn’t process the payment, so you’re now on a grace period. Don’t worry — we’ve saved all your leads and will resume updates as soon as your subscription is renewed.
        </Typography>
      </Box>
      <Box className={styles.footer}>
        {/* <MainActionButton
          title='Request a call'
          actionOnClick={onRequestCall}
          width={210}
          icon={<CallIcon />}
          backgroundMainColor='rgba(224, 232, 249, 1)'
          fontColor='rgba(25, 31, 46, 1)'
        /> */}
        <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
          <>
            <Typography sx={{ fontSize: 14, textAlign: 'center', padding: '4px 4px' }}>
              {"Send email with one time login link to Pay Pro to change payment details."}
            </Typography>
          </>
        }>
          <Box>
            <MainActionButton
              title='Update payment details'
              actionOnClick={onSendEmail}
              width={230}
              icon={<RestartAltIcon />}
              isDisabled={isRequestToSendEmailUpdatePaymentDetails}
              isLoading={isRequestToSendEmailUpdatePaymentDetails}
            />
          </Box>
        </TooltipApp>

        {/* <MainActionButton
          title='Cancel subscription'
          actionOnClick={onCancelPlan}
          width={210}
          icon={<DoNotDisturbIcon />}
          backgroundMainColor='rgba(224, 232, 249, 1)'
          fontColor='rgba(25, 31, 46, 1)'
          isDisabled={isCancelPlanRequest}
          isLoading={isCancelPlanRequest}
        /> */}
      </Box>
      <Typography sx={{ fontSize: '12px', fontFamily: 'Kanit', fontWeight: 300, color: '#6f7d9b', marginTop: '12px' }}>
        *Check your <Link to='/account-details'>account details</Link> to manage your subscription. We’re glad to have you with us!
      </Typography>
    </Box >
  );
};
