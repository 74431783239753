import React from 'react';
import styles from "./StopParsingLeads.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';

export const PopUpStopParsingLeads = () => {
  const { setPopUpStopParsingLeads } = usePopupActions();
  const { isStopLoadSnLinkRequestLoading, uploadingSnLink } = useTableLeadsData();
  const { stopWorkUploadSnLink } = useTableLeadsActions();

  const closePopUp = () => {
    setPopUpStopParsingLeads(false)
  }

  const stopParsingLeads = () => {
    if (uploadingSnLink) {
      stopWorkUploadSnLink({
        uploadingLink: uploadingSnLink
      })
    }
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Stop parsing
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Are you sure to stop parsing?
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={stopParsingLeads}
          isLoading={isStopLoadSnLinkRequestLoading}
          isDisabled={isStopLoadSnLinkRequestLoading}
        />
      </Box>
    </Box>
  );
};
