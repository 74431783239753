import React from 'react';
import styles from "../../RunAutomation.module.scss";
import { Avatar, Badge, Box, Divider, Stack, Typography } from '@mui/material';
import { StyledSwitcher } from '../../../../styledComponents/StyledSwitcher';
import { CustomLinearProgress } from '../../../../../utils/pages/newFeedPage/CustomLinearProgress';
import { useAutomationData } from '../../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { LeadInformation } from '../../../../../types/entities/leadInformation';
import InfiniteScroll from 'react-infinite-scroll-component';

export const AllContentStepTwo = () => {
  const { leadsList, excludeLeadsList, hasMore, currentPage, inputName } = useAutomationData();
  const { toogleLeadToExcludeLeadList, getLeadsToExclude } = useAutomationActions();

  const onLeadsRequestHandler = () => {
    const pageFrom = currentPage === 0 ? currentPage : currentPage * 10;
    const pageTo = pageFrom + 10;

    getLeadsToExclude({
      from: pageFrom,
      to: pageTo,
      name: inputName
    })
    return;
  }

  const toogleLead = (lead: LeadInformation) => {
    toogleLeadToExcludeLeadList(lead)
  }

  return (
    <Box className={styles.content__itemsContainer} id="scrollableDiv">
      <InfiniteScroll
        dataLength={leadsList.length}
        next={onLeadsRequestHandler}
        hasMore={hasMore}
        loader={<CustomLinearProgress />}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: 'center', fontWeight: 300 }}>
            - No more items for load. -
          </p>
        }
      >
        {leadsList.map(lead => {
          const isUserExclude = excludeLeadsList.find(excludelead => excludelead.id === lead.id)
          return (
            <React.Fragment key={lead.id}>
              <Box
                className={styles.content__item}
                sx={{ backgroundColor: lead.haveNewActivity ? '#FDF3D8' : '#FFF' }}
              >
                <Box className={styles.content__leadInfoContainer}>
                  <Stack direction="row">
                    {lead.haveNewActivity ? (<Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          alt=""
                          src="/Ellipse_red.svg"
                          variant="rounded"
                          sx={{ width: '16px', height: '16px' }}
                        />
                      }
                    >
                      <Avatar
                        alt=""
                        src={lead.profileImg}
                        sx={{ width: '40px', height: '40px' }}
                      />
                    </Badge>)
                      : (
                        <Avatar
                          alt=""
                          src={lead.profileImg}
                          sx={{ width: '40px', height: '40px' }}
                        />
                      )}
                  </Stack>
                  <Box className={styles.content__infoContainer}>
                    <Typography className={styles.content__name}>
                      {`${lead.firstName} ${lead.lastName}`}
                    </Typography>
                    <Typography className={styles.content__additionalInfo}>
                      {`${lead.title?.length > 30 ? (lead.title.slice(0, 30) + '...') : (lead.title || '')}`}
                    </Typography>
                  </Box>
                </Box>
                <StyledSwitcher
                  checked={!!isUserExclude}
                  onChange={() => toogleLead(lead)}
                />
              </Box>
              <Divider sx={{ width: '100%' }} />
            </React.Fragment>
          )
        })}
      </InfiniteScroll>
    </Box>
  )
}

