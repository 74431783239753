import { CircularProgress, Button, Box, Zoom } from '@mui/material';
import React, { useEffect } from 'react';
import { useLeadsNotificationData } from '../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';
import { excludePathesToRequest } from "../../utils/general/excludePathesToRequestInAppComponent";
import { useLeadsNotificationActions } from '../../utils/redux/storeHooks/actionsHooks/useLeadsNotificationActions';
import { useLocation } from "react-router-dom";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useSidebarData } from '../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { TooltipApp } from '../tooltipApp/TooltipApp';
import scss from './CheckNotificationsIndicator.module.scss'
import { useAccountDetailsData } from '../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { AccessAppStatuses } from '../../types/PPG/AccessAppStatuses.enum';

export const CheckNotificationsIndicator = () => {
  const pathname = useLocation().pathname;
  const { isNotificationUpdating, isNotificationUpdatingComplete } = useLeadsNotificationData();
  const { getNotificationFromDb, checkActivityByNotification, setNotificationUpdatingComplete } = useLeadsNotificationActions();
  const { setPage, setLeadToSearch, setOrder, setOrderBy, setDateInserted } = useTableLeadsActions();
  const { checkOfValidCookie, checkingCookiesProcess } = useSidebarData()
  const { accessAppStatuses } = useAccountDetailsData();

  const onCheckNotifications = () => {
    checkActivityByNotification();
  }

  useEffect(() => {
    if (!excludePathesToRequest.includes(pathname)) {
      getNotificationFromDb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isNotificationUpdatingComplete) {
      setPage(0);
      setLeadToSearch('');
      setOrder('ASC')
      setOrderBy('firstName')
      setDateInserted({ startDate: null, endDate: null })
      setNotificationUpdatingComplete(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationUpdatingComplete])

  if (accessAppStatuses !== AccessAppStatuses.SUBSCRIPTION_ACTIVE) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="right" title={
        <>
          <span className={scss.toolTipBody}>
            Please, subscribe first
          </span>
        </>
      }>
        <Box sx={{
          height: '40px',
          position: 'absolute',
          top: '10px',
          left: '24px',
          width: '220px',
        }}>
          <Button
            onClick={onCheckNotifications}
            variant="contained"
            disabled={!checkOfValidCookie}
            sx={{
              width: '220px',
              height: '40px',
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: '#FBE7B1',
              color: '#503000',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              padding: '8px 12px',
              fontFamily: 'Kanit',
              boxShadow: 'none',
              borderRadius: '1px',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#FBE7B1',
              },
              justifySelf: 'flex-start'
            }}
          >
            <RotateRightIcon />
            Update notifications
          </Button>
        </Box>
      </TooltipApp>
    )
  }

  if (!checkOfValidCookie) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="right" title={
        <>
          <span className={scss.toolTipBody}>
            Please, update your session on LinkedIn.
          </span>
        </>
      }>
        <Box sx={{
          height: '40px',
          position: 'absolute',
          top: '10px',
          left: '24px',
          width: '220px',
        }}>
          <Button
            onClick={onCheckNotifications}
            variant="contained"
            disabled={!checkOfValidCookie}
            sx={{
              width: '220px',
              height: '40px',
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: '#FBE7B1',
              color: '#503000',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              padding: '8px 12px',
              fontFamily: 'Kanit',
              boxShadow: 'none',
              borderRadius: '1px',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#FBE7B1',
              },
              justifySelf: 'flex-start'
            }}
          >
            <RotateRightIcon />
            Update notifications
          </Button>
        </Box>
      </TooltipApp>
    )
  }

  return (
    <Box sx={{
      height: '40px',
      position: 'absolute',
      top: '10px',
      left: '24px',
      width: '220px',
    }}>
      <Button
        onClick={onCheckNotifications}
        variant={isNotificationUpdating ? 'text' : "contained"}
        disabled={isNotificationUpdating || checkingCookiesProcess}
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '220px',
          height: '40px',
          backgroundColor: isNotificationUpdating ? '#fff' : '#FBE7B1',
          color: '#503000',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          padding: '8px 12px',
          fontFamily: 'Kanit',
          boxShadow: 'none',
          borderRadius: '1px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#FBE7B1',
          },
          justifySelf: 'flex-start'
        }}
      >
        {isNotificationUpdating ? <CircularProgress color='inherit' size={22} /> : <RotateRightIcon />}
        {isNotificationUpdating ? 'Checking for latest updates' : 'Update notifications'}
      </Button>
    </Box>
  )
}

