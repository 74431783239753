import React, { useEffect } from "react";
import styles from "./AccountDetails.module.scss";
import { Box, CircularProgress } from "@mui/material";
import { AccountDetailsHeader } from "./features/AccountDetailsHeader";
import { UserInfo } from "./features/UserInfo";
import { SubscriptionSummary } from "./features/SubscriptionSummary";
import { BillingHistory } from "./features/BillingHistory";
import { usePopupActions } from "../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { getUserInformationFromlocalStorage } from "../../utils/general/get_set_user_information";
import { PaymentFlow } from "../../types/PPG/paymentFlow.enum";
import { useAccountDetailsData } from "../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { AccessAppStatuses } from "../../types/PPG/AccessAppStatuses.enum";
import { UserPermissionList } from "../../types/PPG/UserPermissionList";
import { PPGSubscriptionStatus } from "../../types/PPG/PPGSubscriptionStatus";
import { useSidebarData } from "../../utils/redux/storeHooks/dataHooks/useSidebarData";

export const AccountDetails = () => {
  const {
    setPopupInvoiceSubsctionEndOpen,
    setPopupPPGSubscriptionEndOpen,
    setPopupNewSubscriptionOpen
  } = usePopupActions();
  const { accessAppStatuses, activeSubscription, isGetSubscriptionDetailsRequest } = useAccountDetailsData()
  const { isDataLoading } = useSidebarData();

  useEffect(() => {
    setPopupInvoiceSubsctionEndOpen(false)
    setPopupPPGSubscriptionEndOpen(false)
    setPopupNewSubscriptionOpen(false)

    return () => {
      const { roles, paymentFlow } = getUserInformationFromlocalStorage();

      if (typeof roles === 'string' && roles.split(',').includes('Master')) {
        return;
      }

      if (!activeSubscription
        || (activeSubscription.permission === UserPermissionList.DENY_APP_ACCESS
          && activeSubscription.status === PPGSubscriptionStatus.Terminated)) {
        setPopupNewSubscriptionOpen(true);
        return;
      }

      if (
        accessAppStatuses === AccessAppStatuses.PAYMENT_REQUIRED
        || accessAppStatuses === AccessAppStatuses.API_BLOCKED
      ) {

        if (paymentFlow === PaymentFlow.INVOICE) {
          setPopupInvoiceSubsctionEndOpen(true)
        } else {
          setPopupPPGSubscriptionEndOpen(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessAppStatuses, activeSubscription]);

  if (isDataLoading || isGetSubscriptionDetailsRequest) {
    return (
      <Box className={styles.accountDetails__pageContainerLoading}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box className={styles.accountDetails__pageContainer}>
        <AccountDetailsHeader />
        <Box className={styles.accountDetails__userInfoSubscriptionContainer}>
          <UserInfo />
          <SubscriptionSummary />
        </Box>
        <BillingHistory />
      </Box>
    </>
  );
};
