import React from 'react';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';
import { useAutomationActions } from '../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { Box } from '@mui/material';
import { HeaderPopUp } from './features/HeaderPopUp';
import { FooterPopUp } from './features/FooterPopUp';
import styles from "./RunAutomation.module.scss";
import { PromptContentStepOne } from './features/stepOne/PromptContentStepOne';
import { ActionsStepTwo } from './features/stepTwo/ActionsStepTwo';
import { ExcludedContentStepTwo } from './features/stepTwo/ExcludedContentStepTwo';
import { AllContentStepTwo } from './features/stepTwo/AllContentStepTwo';

export const PopUpRunAutomation = () => {
  const { setPopUpRunAutomation } = usePopupActions();
  const { runAutomationStep, isShowOnlyExcluded } = useAutomationData();
  const { setRunAutomationStep } = useAutomationActions()

  const closePopUp = () => {
    setRunAutomationStep('FIRST')
    setPopUpRunAutomation(false)
  }

  useFreezePopUp()

  return (
    <Box className={styles.popup}>
      <HeaderPopUp closePopUp={closePopUp} />
      <Box className={styles.content}>
        {runAutomationStep === 'FIRST'
          ? <PromptContentStepOne />
          : (
            <>
              <ActionsStepTwo />
              {isShowOnlyExcluded ? <ExcludedContentStepTwo /> : <AllContentStepTwo />}
            </>
          )}

      </Box>
      <FooterPopUp closePopUp={closePopUp} />
    </Box >
  )


};
