import React from 'react';
import styles from "../../RunAutomation.module.scss";
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { StyledSwitcher } from '../../../../styledComponents/StyledSwitcher';
import { useAutomationData } from '../../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { LeadInformation } from '../../../../../types/entities/leadInformation';
import PersonOffIcon from '@mui/icons-material/PersonOff';
export const ExcludedContentStepTwo = () => {
  const {
    excludeLeadsList,
    isGetLeadsToExcludeRequest
  } = useAutomationData();
  const {
    toogleLeadToExcludeLeadList,
  } = useAutomationActions();

  const toogleLead = (lead: LeadInformation) => {
    toogleLeadToExcludeLeadList(lead)
  }

  if (excludeLeadsList.length === 0) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '48px 12px'
      }}>
        <Typography sx={{
          fontFamily: 'Kanit',
          display: 'flex',
          alignItems: 'center',
          gap: '12px'

        }}>
          <PersonOffIcon />
          {"You haven't excluded any leads so far"}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={styles.content__itemsContainer} id="scrollableDiv">
      {!isGetLeadsToExcludeRequest && excludeLeadsList.map(lead => {
        const isUserExclude = excludeLeadsList.find(excludelead => excludelead.id === lead.id)
        return (
          <React.Fragment key={lead.id}>
            <Box className={styles.content__item}>
              <Box className={styles.content__leadInfoContainer}>
                <Avatar
                  alt=""
                  src={lead.profileImg}
                  sx={{ width: '40px', height: '40px' }}
                />
                <Box className={styles.content__infoContainer}>
                  <Typography className={styles.content__name}>
                    {`${lead.firstName} ${lead.lastName}`}
                  </Typography>
                  <Typography className={styles.content__additionalInfo}>
                    {lead.title}
                  </Typography>
                </Box>
              </Box>
              <StyledSwitcher
                checked={!!isUserExclude}
                onChange={() => toogleLead(lead)}
              />
            </Box>
            <Divider sx={{ width: '100%' }} />
          </React.Fragment>
        )
      })}
    </Box>
  )
}

