import { useAppSelector } from '../typedHooks';

export const useAutomationData = () => {
  const {
    currentNumberOfReplyForToday,
    delayBetweenComments,
    scrollStep,
    getAutomationDataloading,
    dailyCommentsQouta,
    isUpdating,
    leadsList,
    excludeLeadsList,
    automationTab,
    isRunAutomationRequest,
    isAutomationRunning,
    isGetLeadsToExcludeRequest,
    currentPage,
    hasMore,
    isShowOnlyExcluded,
    inputName,
    limitOfReplyForADay,
    useStaticPrompt,
    prompts,
    isGetAllPromptsLoading,
    messagePrompt,
    defaultPrompt,
    invitePrompt,
    initialPrompts,
    dontGenerateDM,
    dontGenerateInviteMessage,
    isGenerateMessageUpdating,
    isNewPromptSaving,
    isPromptUpdating,
    isPromptDeleting,
    runAutomationStep,
    runAutomationPromptId
  } = useAppSelector((state) => state.automationReducer);

  return {
    currentNumberOfReplyForToday,
    delayBetweenComments,
    scrollStep,
    getAutomationDataloading,
    dailyCommentsQouta,
    isUpdating,
    leadsList,
    excludeLeadsList,
    automationTab,
    isRunAutomationRequest,
    isAutomationRunning,
    isGetLeadsToExcludeRequest,
    currentPage,
    hasMore,
    isShowOnlyExcluded,
    inputName,
    limitOfReplyForADay,
    useStaticPrompt,
    prompts,
    isGetAllPromptsLoading,
    messagePrompt,
    defaultPrompt,
    invitePrompt,
    initialPrompts,
    dontGenerateDM,
    dontGenerateInviteMessage,
    isGenerateMessageUpdating,
    isNewPromptSaving,
    isPromptUpdating,
    isPromptDeleting,
    runAutomationStep,
    runAutomationPromptId
  };
};