import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../sidebar/Sidebar';
import Header from '../header/Header';
import styles from "./LayoutApp.module.scss";
import { useSidebarData } from '../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { Box } from '@mui/material';

export const LayoutApp = () => {
  const { isSideBarWide } = useSidebarData();
  return (
    <div className={styles.app}>
      <Box className={styles.app__sidebar} sx={{
        width: !isSideBarWide ? '88px' : '272px',
        transition: 'width 0.29s ease-in',
      }}>
        <Sidebar />
      </Box>
      <Box
        className={styles.app__content}
        sx={{
          width: '100%',
        }}
        id='heet-app-page'
      >
        <Header />
        <Box
          className={styles.app__page}
          id='heet-app-content'
          sx={{
            width: !isSideBarWide ? 'calc(100vw -  var(--sidebar-width-narrow))' : 'calc(100vw -  var(--sidebar-width))',
            height: 'calc(100vh - var(--header-height))'
          }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}

