import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { Autocomplete, Box, TextField, Typography, Zoom, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { excludeLeadsDaysOption } from '../../../../utils/pages/newFeedPage/excludeLeadsDaysOption';
import { StyledSwitcher } from '../../../../components/styledComponents/StyledSwitcher';
import { useState } from 'react';
import { TooltipApp } from '../../../../components/tooltipApp/TooltipApp';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    height: '40px',
    padding: '1px 12px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const SelectExcludeLeadComentedBeforeDayCount = () => {
  const { tabId } = useParams();
  const { setExcludeLeadComentedBeforeDayCount } = useNewFeedActions();
  const { tabs } = useNewFeedData();
  const selectedOption = tabs.find(tab => tab.id === tabId)?.excludeLeadComentedBeforeDayCount?.title || null;
  const [isToogle, setIsToogle] = useState(false)

  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
    const selectedOption = excludeLeadsDaysOption.find(option => option.title === newValue) || null;
    setExcludeLeadComentedBeforeDayCount({ newValue: selectedOption, tabId })
  }

  const toogleLead = () => {
    setIsToogle((prevIsToogle) => {
      const newIsToogle = !prevIsToogle;

      if (!newIsToogle) {
        setExcludeLeadComentedBeforeDayCount({ newValue: excludeLeadsDaysOption[0], tabId });
      } else {
        setExcludeLeadComentedBeforeDayCount({ newValue: excludeLeadsDaysOption[1], tabId });
      }

      return newIsToogle;
    });
  }

  React.useEffect(() => {
    if (selectedOption === excludeLeadsDaysOption[0].title) {
      setIsToogle(false)
    } else {
      setIsToogle(true)
    }

  }, [selectedOption])

  return (

    <Box className={style.actions__selectExcludeLeadsWrapper}>
      <TooltipApp TransitionComponent={Zoom} placement="bottom" title={
        <>
          <span className={style.toolTipBody}>
            {`Leads you've commented on in the past 1, 3, 5, or 15 days will be excluded from automation.`}
          </span>
        </>
      }>
        <Box className={style.actions__toggler}>
          <StyledSwitcher
            checked={isToogle}
            onChange={() => toogleLead()}
          />
          <Typography className={style.actions__toggler_title}>
            Exclude recently commented leads
          </Typography>
        </Box>
      </TooltipApp>
      {isToogle && <StyledAutocomplete
        clearIcon={null}
        disablePortal
        id="combo-box-demo"
        options={excludeLeadsDaysOption.filter(option => option.title !== 'without delay').map(option => option.title)}
        sx={{ width: '210px' }}
        value={selectedOption}
        onChange={(event, newValue: string | null) => handleAutocompleteChange(event, newValue)}
        ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
        renderInput={(params) => <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            ),
          }}
        />}
      />}
    </Box>
  )
}

