import React from "react";
import styles from "../AccountDetails.module.scss";
import { Avatar, Box, Typography } from "@mui/material"
import { getUserInformationFromlocalStorage } from "../../../utils/general/get_set_user_information";

export const UserInfo = () => {
  const userInformation = getUserInformationFromlocalStorage();

  return (
    <Box className={styles.accountDetails__userInfoContainer}>
      <Avatar
        src={userInformation.profileImg}
        alt={userInformation.firstName}
        className={styles.accountDetails__userInfoContainer__avatar}
      />
      <Box className={styles.accountDetails__userInfoContainer__info}>
        <Typography className={styles.accountDetails__userInfoContainer__name}>
          {`${userInformation.firstName} ${userInformation.lastName}`}
        </Typography>
        <Typography className={styles.accountDetails__userInfoContainer__title}>
          {userInformation.title}
        </Typography>
        <Typography className={styles.accountDetails__userInfoContainer__email}>
          {userInformation.email}
        </Typography>
      </Box>
    </Box>
  )
}

