import * as React from 'react';
import scss from '../TeamManagementPage.module.scss';
import { Autocomplete, Box, InputAdornment, TextField, Zoom, styled } from '@mui/material';
import { TooltipApp } from '../../../components/tooltipApp/TooltipApp';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTableTeamManagementData } from '../../../utils/redux/storeHooks/dataHooks/useTableTeamManagementData';
import { useTableTeamManagementActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableTeamManagementActions';
import SearchIcon from '@mui/icons-material/Search';
import { MainActionButton } from '../../../components/actionButton/MainActionButton';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: '2px 12px',
    borderRadius: '1px',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
  },
})

export const TeamManagementPageActions = () => {
  const {
    rows
  } = useTableTeamManagementData()
  const {
    setfilteredTeamMembers
  } = useTableTeamManagementActions();

  const [input, setInput] = useState<string>('')

  const filterTeamMembers = async (member: string) => {
    if (!member) {
      setfilteredTeamMembers(rows);
      return;
    }

    const filtredTeam = rows?.filter((row) => row.firstName === member) || [];
    setfilteredTeamMembers(filtredTeam);
  }

  return (
    <div className={scss.actions}>
      <StyledAutocomplete
        sx={{ width: '300px' }}
        onInputChange={(_, newInputValue) => {
          setInput(newInputValue)
        }}
        onChange={(_, newValue: string | null) => {
          filterTeamMembers(newValue);
        }}
        options={
          (rows === null || rows?.length === 0 || !rows)
            ? []
            : Array.from(new Set(rows && rows?.map((item) => item.firstName)))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Search'
            value={input}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <TooltipApp
        TransitionComponent={Zoom}
        title={
          <>
            <span className={scss.toolTipBody}>
              <img src='/tooltip_icon.svg' alt='' /> coming soon
            </span>
          </>
        }>
        <Box>
          <MainActionButton
            title='Invite Member'
            icon={<AddIcon />}
            isDisabled
            width={160}
          />
        </Box>
      </TooltipApp>
    </div>
  )
}