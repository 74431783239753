import { useAppSelector } from '../typedHooks';

export const useAccountDetailsData = () => {
  const {
   isSendingRestorePasswordEmail,
   isSingInRequest,
   isConfirmRestorePasswordRequest,
   isEmailRestorePasswordSent,
   isGetSubscriptionDetailsRequest,
   activeSubscription,
   isGetPlansRequest,
   plans,
   isGetInvoicesRequest,
   invoicePage,
   invoices,
   isRequestUpgradePlan,
   planToUpgrade,
   isCancelPlanRequest,
   totalInvoicesCount,
   isRequestToSendEmailUpdatePaymentDetails,
   accessAppStatuses
  } = useAppSelector((state) => state.accountDetailsReducer);

  return {
    isSendingRestorePasswordEmail,
    isSingInRequest,
    isConfirmRestorePasswordRequest,
    isEmailRestorePasswordSent,
    isGetSubscriptionDetailsRequest,
    activeSubscription,
    isGetPlansRequest,
    plans,
    isGetInvoicesRequest,
    invoicePage,
    invoices,
    isRequestUpgradePlan,
    planToUpgrade,
    isCancelPlanRequest,
    totalInvoicesCount,
    isRequestToSendEmailUpdatePaymentDetails,
    accessAppStatuses
  };
};