import * as React from 'react';
import scss from '../TableLeads.module.scss';
import { Avatar, Badge, Box, CircularProgress, IconButton, Stack, TableBody, TableCell, TableRow, Typography, Zoom } from '@mui/material';
import { TooltipApp } from '../../tooltipApp/TooltipApp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useState } from 'react';
import { TableLoader } from '../../tableLoader/TableLoader';
import { RedCheckbox } from '../../../utils/pages/dashboardLeadsPeoplePage/redCheckbox';
import { actionMenu } from '../../../utils/pages/dashboardLeadsPeoplePage/tableLeads/actionMenu';
import { LeadInformation } from '../../../types/entities/leadInformation';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ReactionOnComment } from './ReactionOnComment';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { getPageFromTo } from '../../../utils/general/getPageFromTo';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';

export const TableLeadsBody = () => {
  const {
    rows,
    selected,
    loading,
    selectedRowForMenu,
    page,
    rowsPerPage,
    leadToSearch,
    order,
    orderBy,
    isDeletingOrUpdateProcess,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    filterLeadName,
    filterCompanyName,
    selectedLeadConnectionPossition,
    isHaveNewActivitySelect,
    isAllSelected
  } = useTableLeadsData()
  const {
    toggleSelected,
    setSelectedRowForMenu,
    deleteLeadsAndUpdatedLeadList,
    updateFullLeadInfo,
    markLeadsAsRead,
    setIsAllSelected
  } = useTableLeadsActions()
  const { dontGenerateDM, dontGenerateInviteMessage } = useAutomationData()
  const { setPopupSendMessageOpen, generateIceBreake, setUserDataToSendMessage } = usePopupActions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, row: LeadInformation) => {
    setSelectedRowForMenu(row);
    setAnchorEl(event.currentTarget);
  };

  const onDeleteLead = async () => {
    setAnchorEl(null);
    const { pageFrom, pageTo } = getPageFromTo(page, rowsPerPage);
    deleteLeadsAndUpdatedLeadList({
      selectedRows: [String(selectedRowForMenu.id)],
      from: pageFrom,
      to: pageTo,
      order,
      orderBy,
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName: filterLeadName || leadToSearch,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect
      },
      isAllSelected,
    })
  };

  const onMarkAsRead = () => {
    setAnchorEl(null);
    const { pageFrom, pageTo } = getPageFromTo(page, rowsPerPage);

    markLeadsAsRead({
      selectedRows: [String(selectedRowForMenu.id)],
      from: pageFrom,
      to: pageTo,
      order,
      orderBy,
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName: filterLeadName || leadToSearch,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect,
      },
      isAllSelected,
    })
  }

  const onUpdateFullLeadInfo = async () => {
    setAnchorEl(null);
    updateFullLeadInfo({
      leadIdentity: selectedRowForMenu.leadIdentitySN,
      firstName: selectedRowForMenu.firstName,
      lastName: selectedRowForMenu.lastName
    })
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowForMenu(null)
  };

  const handleSelectLead = (id: string) => {
    setIsAllSelected(false)
    toggleSelected(id)
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const openPopupSendMessageAndGenerateIceBreak = async (
    id: string,
    leadConnectionPosition: number,
    userFirstLastName: string,
    profileImg: string,
    likedYourComments: number,
    repliedToYourComments: number,
    leadIdentitySn: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    haveNewActivity: any
  ) => {
    if (leadConnectionPosition === 1 || (likedYourComments !== 0 || repliedToYourComments !== 0)) {
      const sendMessagePayload = {
        id,
        leadConnectionPosition,
        userFirstLastName,
        profileImg,
        likedYourComments,
        repliedToYourComments,
        leadIdentitySn,
        haveNewActivity
      }

      if (leadConnectionPosition === 1 && dontGenerateDM) {
        setPopupSendMessageOpen(true);
        setUserDataToSendMessage(sendMessagePayload)
        return;
      }

      if (leadConnectionPosition === 1 && !dontGenerateDM) {
        setPopupSendMessageOpen(true);
        generateIceBreake(sendMessagePayload)
        return;
      }

      if (dontGenerateInviteMessage) {
        setPopupSendMessageOpen(true);
        setUserDataToSendMessage(sendMessagePayload)
        return;
      }

      setPopupSendMessageOpen(true);
      generateIceBreake(sendMessagePayload)

    }
  };

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {loading && rows?.length > 0 && <TableLoader />}
      {rows?.map((row) => {
        const isItemSelected = isSelected(String(row.id));
        const sortedActivity = [...row.leadActivityOnReply].sort((a, b) => {
          const dateA = new Date(a.time);
          const dateB = new Date(b.time);
          return dateB.getTime() - dateA.getTime();
        })
        const replyActivity = sortedActivity.find(activity => activity.typeOfActivity === 'Reply');
        const newestActivity = sortedActivity[0];

        return (
          <TableRow
            hover
            key={row.id}
            sx={{
              backgroundColor: isItemSelected ? '#E0E8F9' : row.haveNewActivity ? '#FDF3D8' : 'transparent',
              maxHeight: '64px'
            }}
          >
            <TableCell sx={{ padding: '0px 12px', minWidth: 30, maxWidth: 30 }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <RedCheckbox
                  onClick={() => handleSelectLead(String(row.id))}
                  checked={isItemSelected}
                />
              </Box>
            </TableCell>
            <TableCell sx={{ minWidth: 200, maxWidth: 200, padding: '12px 12px' }}>
              <Box className={scss.profileWrapper}>
                <a
                  className={scss.firstLastNameText}
                  href={row.profileUrl?.toString()}
                  target='_blank'
                  rel="noreferrer"
                >
                  <Stack direction="row">
                    {row.haveNewActivity ? (<Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          alt=""
                          src="/Ellipse_red.svg"
                          variant="rounded"
                          sx={{ width: '16px', height: '16px' }}
                        />
                      }
                    >
                      <Avatar
                        alt=""
                        src={row.profileImg}
                        sx={{ width: '40px', height: '40px' }}
                      />
                    </Badge>)
                      : (
                        <Avatar
                          alt=""
                          src={row.profileImg}
                          sx={{ width: '40px', height: '40px' }}
                        />
                      )}
                  </Stack>
                </a>
                <Box className={scss.userInfo} sx={{ marginLeft: '12px' }}>
                  <Box className={scss.firstLastNameText}>
                    {row.firstName?.length + row.lastName?.length > 17
                      ? `${row.firstName} ${row.lastName}`.slice(0, 17) + '...'
                      : `${row.firstName} ${row.lastName}`}
                  </Box>
                  <Box className={scss.titleText}>
                    {row.title && row.title !== undefined && row.title?.length > 18
                      ? `${row.title.slice(0, 18)}...`
                      : row.title}
                  </Box>
                </Box>
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px', }}
              align="center"
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}>
                <Typography sx={{
                  fontFamily: 'Kanit',
                  fontSize: 16,
                  fontWeight: 300,
                  backgroundColor: '#E0E8F9',
                  padding: '0 8px',
                }}>
                  {row.leadConnectionPosition === 1 ? '1st' : '2nd'}
                </Typography>
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 150, maxWidth: 150, padding: '0px 12px' }}
              align='left'
            >
              {row.companyName && row.companyName !== undefined && row.companyName?.length > 28
                ? `${row.companyName.slice(0, 28)}...`
                : row.companyName}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center"
            >
              {row.myCommentsCount || 0}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center"
            >
              {row.leadActivityOnReply?.filter(activity => activity.typeOfActivity === 'Reply').length || 0}
            </TableCell>

            <TableCell
              sx={{ minWidth: isFilterOpen ? 50 : 200, maxWidth: isFilterOpen ? 50 : 200, padding: '0px 12px' }}
              align="left"
            >
              <ReactionOnComment
                leadActivityOnReply={replyActivity ? replyActivity : newestActivity}
                isFilterOpen={isFilterOpen}
              />
            </TableCell>


            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <TooltipApp TransitionComponent={Zoom} placement="left-start" title={
                <>
                  <span className={scss.toolTipBody}>
                    {row.leadConnectionPosition === 1 && row.haveNewActivity && 'Send DM'}
                    {row.leadConnectionPosition !== 1 && row.haveNewActivity && row.statusOfInvitation !== "PENDING" && 'Send invitation'}
                    {row.leadConnectionPosition !== 1 && row.haveNewActivity && row.statusOfInvitation === "PENDING" && 'You have alredy sent invitation'}
                    {!row.haveNewActivity && row.statusOfInvitation === "PENDING" && 'You have alredy sent invitation'}
                    {!row.haveNewActivity && row.statusOfInvitation !== "PENDING" && 'Send a connection request & message after you have engaged the lead'}
                  </span>
                </>
              }>
                <div>
                  <IconButton color="primary" aria-label="dm"
                    disabled={row.statusOfInvitation === "PENDING" || !row.haveNewActivity || isDeletingOrUpdateProcess}
                    onClick={() => openPopupSendMessageAndGenerateIceBreak(
                      row.id.toString(),
                      parseInt(row.leadConnectionPosition.toString()
                      ),
                      row.firstName + " " + row.lastName,
                      row.profileImg.toString(),
                      parseInt(''),
                      parseInt(''),
                      row.leadIdentitySN.toString(),
                      row.haveNewActivity
                    )}
                  >
                    {
                      row.leadConnectionPosition === 1
                        ? <ChatBubbleOutlineIcon style={{
                          color: '#6D30B7',
                          opacity: (row.statusOfInvitation === "PENDING" || !row.haveNewActivity) ? 0.5 : 1
                        }} />
                        : < PersonAddAltIcon style={{
                          color: '#6D30B7',
                          opacity: (row.statusOfInvitation === "PENDING" || !row.haveNewActivity) ? 0.5 : 1
                        }} />
                    }
                  </IconButton>
                </div>
              </TooltipApp>
            </TableCell>

            <TableCell
              key={row.id}
              align="center"
              sx={{ minWidth: 50, maxWidth: 50, position: 'relative', padding: '0px 12px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenMenu(event, row);
                  }}
                  disabled={isDeletingOrUpdateProcess}
                  sx={{
                    display: isDeletingOrUpdateProcess
                      && (selectedRowForMenu?.id === row.id || selected.includes(String(row.id)))
                      ? 'none'
                      : 'block'
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                {isDeletingOrUpdateProcess
                  && (selectedRowForMenu?.id === row.id || selected.includes(String(row.id)))
                  && (
                    <Box sx={{
                      height: 40,
                      width: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <CircularProgress
                        size={26}
                        color='inherit'
                      />
                    </Box>
                  )}
              </Box>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                  disablePadding: true,
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
                    width: '150px',
                    padding: '4px',
                    height: '128px',
                    overflowX: 'unset',
                    overflowY: 'unset'
                  },
                }}
              >
                {actionMenu.map((option) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const handleClickItem = (event: any) => {
                    event.stopPropagation();
                    option === 'Delete' && onDeleteLead()
                    option === 'Update Lead' && onUpdateFullLeadInfo()
                    option === 'Mark as read' && onMarkAsRead()
                  };

                  return (
                    <MenuItem
                      key={option}
                      sx={{
                        padding: '0px',
                        height: '40px',
                      }}
                      // disabled={option === 'Update Lead' && row.userUuid !== '61e2707e-17c0-493c-958c-d4517ed8a280'}
                      onClick={handleClickItem}
                    >
                      {option === 'Delete' && (
                        <>
                          <ListItemIcon>
                            <DeleteOutlineRoundedIcon sx={{
                              height: '40px',
                              color: '#191F2E'
                            }} />
                          </ListItemIcon>
                          <ListItemText>{option}</ListItemText>
                        </>
                      )
                      }
                      {option === 'Update Lead' && (
                        <>
                          <ListItemIcon>
                            <RefreshIcon sx={{
                              height: '40px',
                              color: '#191F2E'
                            }} />
                          </ListItemIcon>
                          <ListItemText>{option}</ListItemText>
                        </>
                      )
                      }
                      {option === 'Mark as read' && (
                        <>
                          <ListItemIcon>
                            <DoneAllIcon sx={{
                              height: '40px',
                              color: '#191F2E'
                            }} />
                          </ListItemIcon>
                          <ListItemText>{option}</ListItemText>
                        </>
                      )
                      }
                    </MenuItem>
                  );
                })}
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody >
  )
}