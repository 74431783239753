import { PromptV2 } from "../../../types/entities/promptV2"
import { PromptV2Link } from "../../../types/pages/automationPage/promptV2Names"

export const checkIsCountUserPromptsMoreThanFive = (prompts: PromptV2[]) => {
  const promptsWithoutDefault = prompts.filter(prompt => {
    return prompt.linkName !== PromptV2Link.default
      && prompt.linkName !== PromptV2Link.newPrompt
      && prompt.linkName !== PromptV2Link.invitePrompt
      && prompt.linkName !== PromptV2Link.messagePrompt
  })
  return promptsWithoutDefault.length >= 5
}