import React from "react";
import styles from "../AccountDetails.module.scss";
import { Box, Typography } from "@mui/material"
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export const AccountDetailsHeader = () => {
  return (
    <Box className={styles.accountDetails__AccountDetailsHeader}>
      <Typography className={styles.accountDetails__AccountDetailsHeader__title}>
        Account Details
      </Typography>
      <a
        href='https://meetings-eu1.hubspot.com/michael-pihosh/heet'
        target='_blank'
        className={styles.accountDetails__AccountDetailsHeader__contactButton}
        rel="noreferrer"
      >
        <SupportAgentIcon />
        Contact support
      </a>
    </Box>
  )
}

