import * as React from 'react';
import { PromptV2 } from '../../../../../types/entities/promptV2';
import { useAutomationActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { StyledTextFieldPromptNameInput } from '../../../../../components/styledComponents/StyledTextFieldPromptNameInput';
import { PromptV2Link } from '../../../../../types/pages/automationPage/promptV2Names';

type Props = {
  currentPrompt: PromptV2;
}

export const PromptName = ({
  currentPrompt,
}: Props) => {
  const { setInputPromptName } = useAutomationActions()

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPromptName({
      inputValue: event.target.value,
      currentPromptLink: currentPrompt.linkName
    });
  };

  return (
    <StyledTextFieldPromptNameInput
      fullWidth
      value={currentPrompt.name}
      disabled={currentPrompt.linkName === PromptV2Link.default
        || currentPrompt.linkName === PromptV2Link.invitePrompt
        || currentPrompt.linkName === PromptV2Link.messagePrompt}
      onChange={handleChangeInput}
    />
  )
}

