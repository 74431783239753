import { Box, Typography } from "@mui/material"
import * as React from 'react';
import scss from '../../../AutomationPage.module.scss'
import { PromptV2 } from "../../../../../types/entities/promptV2";
import { useAutomationActions } from "../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions";
import { ToogleType } from "../../../../../types/pages/automationPage/toogleType";
import { StyledSwitcher } from "../../../../../components/styledComponents/StyledSwitcher";

type Props = {
  currentPrompt: PromptV2;
}

export const PromptCheckBoxes = ({ currentPrompt }: Props) => {
  const { setHashTagsChecked, setEmojiChecked } = useAutomationActions()

  const onTooglesChange = (event: React.ChangeEvent<HTMLInputElement>, toogleType: ToogleType) => {
    const isChecked = event.target.checked;
    switch (toogleType) {
      case ToogleType.hashtag:
        setHashTagsChecked({
          isChecked,
          currentPromptLink: currentPrompt.linkName
        })
        return;
      case ToogleType.emoji:
        setEmojiChecked({
          isChecked,
          currentPromptLink: currentPrompt.linkName
        })
        return;
    }
  }

  return (
    <Box className={scss.promptSetting__checkboxesWrapper}>
      <Box className={scss.promptSetting__checkboxWrapper}>
        <Typography className={scss.promptSetting__checkBoxTitle}>
          Generate hashtags
        </Typography>
        <StyledSwitcher
          checked={currentPrompt.includeHashTags}
          onChange={(e) => onTooglesChange(e, ToogleType.hashtag)}
        />
      </Box>
      <Box className={scss.promptSetting__checkboxWrapper}>
        <Typography className={scss.promptSetting__checkBoxTitle}>
          Include emoji
        </Typography>
        <StyledSwitcher
          checked={currentPrompt.includeEmoji}
          onChange={(e) => onTooglesChange(e, ToogleType.emoji)}
        />
      </Box>
    </Box>
  )
}

