export const initialPrompt = {
  id: 0,
  userUuid: 'initial-user-uuid',
  name: 'new prompt',
  prompt: 'add your prompt',
  linkName: 'newprompt',
  show: true,
  usage: 0,
  contextVariables: ['First name', 'Company name'],
  includeHashTags: false,
  includeEmoji: true,
}