import { DeleteMembersPayload, DeleteMembersResponse, GetTeamListPayload, GetTeamListResponse } from "./types"
import { getRequest, postRequest } from "../../../general/https"
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition"
import { checkErrorStatus } from "../../../general/checkErrorStatus"

export const getTeamList = createAsyncThunkCheckSubscription<GetTeamListResponse, GetTeamListPayload>(
  'tableTeamManagementSlice/getTeamList',
  async ({userInformation}, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/team-information/${userInformation.organizationId}`, false)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteTeamMemberAndUpdateTeamList = createAsyncThunkCheckSubscription<DeleteMembersResponse, DeleteMembersPayload>(
  'tableTeamManagementSlice/deleteMemberAndUpdateTeamList',
  async ({selectedRowId}, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/team-information/remove-member`, { id: selectedRowId }, true)
      checkErrorStatus(response)     
      return response.data
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)