import * as React from 'react';
import scss from '../../AutomationPage.module.scss';
import { Box } from '@mui/material';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';

export const CommentsLeft = () => {
  const { currentNumberOfReplyForToday, limitOfReplyForADay } = useAutomationData();
  const commentSended = limitOfReplyForADay - currentNumberOfReplyForToday;
  const viewedCommentSend = commentSended >= 0 ? currentNumberOfReplyForToday : 0;
  return (
    <Box className={scss.messageCounter}>
      <Box className={scss.messageCounter__title}>
        Comments left for today:
      </Box>
      <Box className={scss.messageCounter__count}>
        <Box className={scss.messageCounter__commentsLeft}>
          {viewedCommentSend}
        </Box>
        <Box className={scss.messageCounter__commentsSum}>
          {`/${limitOfReplyForADay}`}
        </Box>
      </Box>
    </Box>
  )
}

