import { useEffect } from "react";

export const useFreezePopUp = () => {
  useEffect(() => {
    document.body.classList.add('popup-open');
    return () => {
      document.body.classList.remove('popup-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}