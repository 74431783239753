import { getRequest, postRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { GetLeadsNotificationResponse } from "./types";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";

export const checkActivityByNotification = createAsyncThunkCheckSubscription<GetLeadsNotificationResponse>(
  'leadsNotificationSlice/checkActivityByNotification',
   async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/parsing/check-activity-by-notification`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const updateViewNotificationStatus = createAsyncThunkCheckSubscription<GetLeadsNotificationResponse>(
  'leadsNotificationSlice/updateViewNotificationStatus',
   async (_, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/update-view-status`, {}, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const getNotificationFromDb = createAsyncThunkCheckSubscription<GetLeadsNotificationResponse>(
  'leadsNotificationSlice/getNotificationFromDb',
   async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/lead-information/get-leads-notification`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)
