import { PromptV2 } from "../../../types/entities/promptV2";
import { DefaultPrompts } from "../../../types/pages/automationPage/defaultsPrompts";
import { PromptV2Link } from "../../../types/pages/automationPage/promptV2Names";

export const getFiltredPrompts = (prompts: PromptV2[]) => {
  return prompts.reduce<DefaultPrompts>((acc, prompt) => {
    if (!acc.userPrompts) {
      acc.userPrompts = [];
    }

    if (prompt.linkName === PromptV2Link.default) {
      acc.defaultPrompt = prompt;
    } else if (prompt.linkName === PromptV2Link.messagePrompt) {
      acc.messagePrompt = prompt;
    } else if (prompt.linkName === PromptV2Link.invitePrompt) {
      acc.invitePrompt = prompt;
    } else if (prompt.linkName !== PromptV2Link.newPrompt) {
      acc.userPrompts.push(prompt);
    }
    return acc;
  }, {} as DefaultPrompts);
}