import { Box } from "@mui/material";
import React from "react";
import styles from "../AccountDetails.module.scss";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import LockIcon from '@mui/icons-material/Lock';
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { SubscriptionActions } from "./SubscriptionActions";



export const AccountDetailsActions = () => {
  const { setPopupRestorePasswordOpen } = usePopupActions();

  const onResetPassword = () => {
    setPopupRestorePasswordOpen(true)
  }

  return (
    <Box className={styles.accountDetails__subscriptionSummary__actions}>
      <Box className={styles.accountDetails__subscriptionSummary__actionsUser}>
        <MainActionButton
          title="Reset Password"
          icon={<LockIcon />}
          backgroundMainColor="rgba(224, 232, 249, 1)"
          borderRadius="2px"
          width={170}
          fontColor="rgba(25, 31, 46, 1)"
          fontWeight={400}
          actionOnClick={onResetPassword}
        />
      </Box>
      <Box className={styles.accountDetails__subscriptionSummary__actionsSubscription}>
        <SubscriptionActions />
      </Box>
    </Box>
  )
}

