import * as React from 'react';
import scss from './AutomationPage.module.scss'
import { Box } from '@mui/material';
import { PagesTabs } from '../../components/pagesTabs/PagesTabs';
import { TabsType } from '../../types/general/pagesTabs';
import { pagesTabs } from '../../utils/pages/automationPage/tabs';
import { Outlet } from 'react-router-dom';
import { PageTitle } from './features/PageTitle';


export const AutomationPage = () => {
  return (
    <Box className={scss.pageWrapper}>
      <PagesTabs tabs={pagesTabs} tabsType={TabsType.setting} />
      <Box className={scss.pageContentWrapper}>
        <PageTitle />
        <Outlet />
      </Box>
    </Box>
  )
}

