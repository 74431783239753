import { getRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { CheckValidCookieResponse, GetSideBarDataResponse } from "./types";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";

export const getSideBarData = createAsyncThunkCheckSubscription<GetSideBarDataResponse>(
  'sidebarSlice/getSideBarData',
   async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/users-information/get-sidebar-data`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }  
)

export const checkValidCookie = createAsyncThunkCheckSubscription<CheckValidCookieResponse>(
  'sidebarSlice/checkValidCookie',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await getRequest('/users-information/check-valid-cookie', true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)