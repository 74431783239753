import { Box, Typography } from '@mui/material';
import * as React from 'react';
import scss from '../../AutomationPage.module.scss'
import { SettingsWrapper } from './settings/SettingWrapper';
import { DailyCommentsQouta } from './settings/DailyCommentsQouta';
import { DelayBetweenComments } from './settings/DelayBetweenComments';

type Props = {
  handleChangeSlider: (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => void;
}

export const Settings = ({ handleChangeSlider }: Props) => {
  return (
    <Box className={scss.setting}>
      <Typography className={scss.setting__mainTitle}>
        Automation settings:
      </Typography>

      <Box className={scss.setting__wrapper}>
        <SettingsWrapper
          title='Daily comments qouta'
        >
          <DailyCommentsQouta handleChangeSlider={handleChangeSlider} />
        </SettingsWrapper>

        <SettingsWrapper
          title='Set a time delay between the comments.'
        >
          <DelayBetweenComments handleChangeSlider={handleChangeSlider} />
        </SettingsWrapper>
      </Box>
    </Box >
  )
}

