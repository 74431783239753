import * as React from 'react';
import { Box, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { TableLoader } from '../../../../components/tableLoader/TableLoader';
import { useAccountDetailsData } from '../../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import moment from 'moment';
import { InvoiceStatus } from '../../../../types/PPG/Invoice';
import TimerIcon from '@mui/icons-material/Timer';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const TableBillsBody = () => {
  const { invoices, isGetInvoicesRequest } = useAccountDetailsData();

  const getStatusIconAndText = (orderStatusId: number) => {
    switch (orderStatusId) {
      case InvoiceStatus.Waiting:
        return {
          text: 'pending',
          icon: <TimerIcon sx={{ color: 'rgba(177, 208, 232, 1)' }} />
        }
      case InvoiceStatus.Processed:
        return {
          text: 'confirm',
          icon: <CheckIcon sx={{ color: 'rgba(191, 232, 177, 1)' }} />
        }
      case InvoiceStatus.Canceled:
        return {
          text: 'canceled',
          icon: <DoDisturbIcon sx={{ color: 'rgba(255, 183, 163, 1)' }} />
        }
      case InvoiceStatus.Refunded:
        return {
          text: 'refunded',
          icon: <CurrencyExchangeIcon sx={{ color: 'rgba(177, 208, 232, 1)' }} />
        }
      case InvoiceStatus.Chargeback:
        return {
          text: 'charge back',
          icon: <AccountBalanceWalletIcon sx={{ color: 'rgba(177, 208, 232, 1)' }} />
        }
      default:
        return {
          text: 'pending',
          icon: <TimerIcon />
        }
    }
  }

  const onOpenLink = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {isGetInvoicesRequest && invoices?.length > 0 && <TableLoader />}
      {invoices?.map((invoice) => {
        const status = getStatusIconAndText(invoice.orderStatusId)
        return (
          <TableRow
            hover
            key={invoice.id}
            sx={{
              maxHeight: '64px',
            }}
          >
            <TableCell
              sx={{ minWidth: 300, maxWidth: 300, padding: '12px 12px' }}
              align='left'
            >
              <Box sx={{ fontFamily: 'Inter' }}>
                {moment(invoice.placedAtUtc).format("M/D/YYYY")}
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 300, maxWidth: 300, padding: '0px 12px' }}
              align="center"
            >
              <Box sx={{ fontFamily: 'Inter' }}>
                {invoice.paymentMethodName}
              </Box>
            </TableCell>

            <TableCell sx={{ minWidth: 200, maxWidth: 200, padding: '0px 12px' }} align="center">
              <Box sx={{ fontFamily: 'Kanit', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '6px', width: '100%' }}>
                {status.icon}
                {status.text}
              </Box>
            </TableCell>

            <TableCell sx={{ minWidth: 100, maxWidth: 100, padding: '0px 12px' }} align="center">
              <IconButton onClick={() => onOpenLink(invoice.invoiceUrl)}>
                <LinkIcon sx={{ fontSize: '22px', color: 'rgba(28, 27, 31, 1)' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}