import { ModalsName } from "../../../types/PPG/ModalsName";
import { PaymentFlow } from "../../../types/PPG/paymentFlow.enum";
import { PPGSubscriptionStatus } from "../../../types/PPG/PPGSubscriptionStatus";
import { SubscriptionI } from "../../../types/PPG/SubscriptionI";
import { UserPermissionList } from "../../../types/PPG/UserPermissionList";
import { getUserInformationFromlocalStorage } from "../../general/get_set_user_information";

export const getBlockedModalName = (subscription: SubscriptionI) => {
  const { paymentFlow } = getUserInformationFromlocalStorage()

  if(paymentFlow === PaymentFlow.INVOICE) {
    if(!subscription) {
      return ModalsName.PPG_NEW_SUBSCRIPTION;
    }
    if(subscription.permission === UserPermissionList.DENY_APP_ACCESS) {
      return ModalsName.INVOICE_SUBSCRIPTION_END;
    }
  }

  if(paymentFlow === PaymentFlow.PPG) {
    if(!subscription) {
      return ModalsName.PPG_NEW_SUBSCRIPTION;
    }

    if(subscription.permission === UserPermissionList.ALLOW_APP_ACCESS && subscription.status === PPGSubscriptionStatus.Terminated) {
      return null;
    }

    if(subscription.permission === UserPermissionList.DENY_APP_ACCESS && subscription.status !== PPGSubscriptionStatus.Terminated) {
      return ModalsName.PPG_SUBSCRIPTION_SUSPENDED;
    }

    if(subscription.status === PPGSubscriptionStatus.Terminated) {
      return ModalsName.PPG_INACTIVE_SUBSCRIPTION_MODAL;
    }
  }

  if(subscription?.permission === UserPermissionList.DENY_APP_ACCESS) {
    return ModalsName.INVOICE_SUBSCRIPTION_END;
  }

  return null;
}