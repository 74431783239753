import * as React from 'react';
import scss from '../../AutomationPage.module.scss'
import { Box, Divider, LinearProgress, Zoom } from '@mui/material';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import AddIcon from '@mui/icons-material/Add';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useEffect } from 'react';
import { initialPrompt } from '../../../../utils/pages/automationPage/initialPrompt';
import { PromptV2Link } from '../../../../types/pages/automationPage/promptV2Names';
import { TooltipApp } from '../../../../components/tooltipApp/TooltipApp';
import { getFiltredPrompts } from '../../../../utils/pages/automationPage/getFiltredPrompts';
import { checkIsCountUserPromptsMoreThanFive } from '../../../../utils/pages/automationPage/checkIsCountUserPromptsMoreThanFive';

export const PromptsList = () => {
  const { getAllPrompts, createPrompt } = useAutomationActions()
  const { isGetAllPromptsLoading, prompts } = useAutomationData()
  const { defaultPrompt, messagePrompt, invitePrompt, userPrompts } = getFiltredPrompts(prompts)
  const isCountPromptsMoreThanFive = checkIsCountUserPromptsMoreThanFive(prompts)

  const navigate = useNavigate();
  const defaultPath = '/settings/prompts'
  const { promptLink } = useParams();

  const activeLinkStyle = ({ isActive }: { isActive: boolean }) => {
    return {
      backgroundColor: isActive ? "#F7F9FD" : "transparent",
      borderLeft: isActive ? "3px solid #3B2470" : "none",
    };
  }

  const onCreateNewPrompt = () => {
    let newPrompt = prompts.find(prompt => prompt.linkName === PromptV2Link.newPrompt)
    if (!newPrompt) {
      newPrompt = { ...initialPrompt }
      createPrompt(newPrompt)

    }
    navigate(`${defaultPath}/${newPrompt.linkName}`)
  }

  useEffect(() => {
    if (!prompts.length) {
      getAllPrompts()
      if (promptLink === PromptV2Link.newPrompt) {
        onCreateNewPrompt();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  if (isGetAllPromptsLoading) {
    return (
      <Box className={scss.promptsListWrapper}>
        <LinearProgress color='inherit' sx={{ width: '100%' }} />
      </Box>
    )
  }

  return (
    defaultPrompt && <Box className={scss.promptsListWrapper}>
      {isCountPromptsMoreThanFive ? (<TooltipApp TransitionComponent={Zoom} placement="bottom-end" title={
        <>
          <span className={scss.toolTipBody}>
            You can create only 5 prompts
          </span>
        </>
      }>
        <div>
          <MainActionButton
            title='Create New Custom Prompt'
            icon={<AddIcon />}
            iconPosition='left'
            width={300}
            isDisabled
          />
        </div>
      </TooltipApp>)
        : (
          <MainActionButton
            title='Create New Custom Prompt'
            icon={<AddIcon />}
            iconPosition='left'
            width={300}
            actionOnClick={onCreateNewPrompt}
          />
        )}

      < Box className={scss.promptsList}>
        <NavLink
          className={scss.promptsList__link}
          to={`${defaultPath}/${defaultPrompt.linkName}`}
          end
          style={activeLinkStyle}
        >
          {defaultPrompt.name}
        </NavLink>
        {userPrompts.map((prompt, index) => (
          <NavLink
            key={prompt.id}
            className={`${scss.promptsList__link} ${scss.promptsList__link_custom}`}
            to={`${defaultPath}/${prompt.linkName}`}
            end
            style={activeLinkStyle}
          >
            <Box>
              {prompt.name}
            </Box>
          </NavLink>
        ))}
        <Divider sx={{ width: '100%', height: '3px' }} />
        <NavLink
          className={scss.promptsList__link}
          to={`${defaultPath}/${messagePrompt.linkName}`}
          end
          style={activeLinkStyle}
        >
          {messagePrompt.name}
        </NavLink>
        <NavLink
          className={scss.promptsList__link}
          to={`${defaultPath}/${invitePrompt.linkName}`}
          end
          style={activeLinkStyle}
        >
          {invitePrompt.name}
        </NavLink>
      </Box>
    </Box >
  )
}

