import { AccessAppStatuses } from "../../types/PPG/AccessAppStatuses.enum"
import { apiErrorStatuses } from "./apiErrorStatuses"

export const checkErrorStatus = (response: {status: number, message?: string, data: {message: string}}) => {
  if(apiErrorStatuses.includes(response?.status)) {
    if(response?.status === 402) {
      throw new Error(AccessAppStatuses.PAYMENT_REQUIRED)
    }

    throw new Error(response?.data?.message 
      || response.message 
      || 'Something went wrong, try again later')
  }
}

export const isApiBlocked = (errorMessage: unknown) => {
  return(errorMessage === AccessAppStatuses.API_BLOCKED || errorMessage === AccessAppStatuses.PAYMENT_REQUIRED)
}