import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {newFeedSliceInitialState,} from './types';
import { options } from '../../../pages/newFeedPage/daysOptions';
import { initialPageForTab, initialTab } from '../../../pages/newFeedPage/initialTab';
import { FeedTab } from '../../../../types/pages/newFeedPage/feedTab';
import { DaysOption } from '../../../../types/pages/newFeedPage/daysOption';
import { createNewTab, deletePost, deleteTab, generateMessage, getAllTabs, getFeed, getFeedGeneralTab, getPromptList, sendMessage, updateTab, updateTitleTab } from './thunks';
import { excludeLeadsDaysOption } from '../../../pages/newFeedPage/excludeLeadsDaysOption';

const initialState: newFeedSliceInitialState = {
  tabs: [initialTab],
  initialTabs: [initialTab],
  isSavingTab: false,
  isDeletingtab: false,
  isUpdatingTab: false,
  isUpdatingTitleTab: false,
  deleteOrUpdateTabId: null,
  isSuccessDelete: false,
  currentLoadingTab: '',
  //Tabs state
  isAllTabsLoading: true,
  isAllTabsError: false,
  //Feed state
  allFeed: [],
  isGetFeedLoading: false,
  isGetFeedError: false,
  pagesForTabs: [initialPageForTab],
  isGetFeedCountLoading: false,
  //Prompt list
  promptList: [],
  generatedMessages: [],
  postIdGeneratedMessage: [],
  //Send message
  postIdsSendingMessage: [],
  //Delete post
  postIdsDeliting: [],
};

export const newFeedSlice = createSlice({
  name: 'newFeedSlice',
  initialState,
  reducers: {
    setTabs: (state, { payload }: PayloadAction<FeedTab[]>) => {
      state.tabs = payload;
    },
    setIncludesKeywordForTab: (state, { payload }: PayloadAction<{newValue: string[], tabId: string}>) => {
      const currentTab = state.tabs.find(tab => tab.id === payload.tabId)
      currentTab.includeKeywords = payload.newValue
    },
    setExcludesKeywordForTab: (state, { payload }: PayloadAction<{newValue: string[], tabId: string}>) => {
      const currentTab = state.tabs.find(tab => tab.id === payload.tabId)
      currentTab.excludeKeywords = payload.newValue
    },
    setDaysOptionForTab: (state, { payload }: PayloadAction<{newValue: DaysOption, tabId: string}>) => {
      const currentTab = state.tabs.find(tab => tab.id === payload.tabId)
      currentTab.daysOption = payload.newValue
    },
    setExcludeLeadComentedBeforeDayCount: (state, { payload }: PayloadAction<{newValue: DaysOption, tabId: string}>) => {
      const currentTab = state.tabs.find(tab => tab.id === payload.tabId)
      currentTab.excludeLeadComentedBeforeDayCount = payload.newValue
    },
    setDeleteOrUpdateTabId: (state, { payload }: PayloadAction<string>) => {
      state.deleteOrUpdateTabId = payload
    },
    resetCurrenPagesForTab: (state, { payload }: PayloadAction<string | number>) => {
      const currentPages = state.pagesForTabs.find(tab => tab.tabId === payload)
      currentPages.page = 0;
    },
    resetFeedForTab: (state, { payload }: PayloadAction<string | number>) => {
      const currentTab = state.tabs.find(tab => tab.id === payload)
      currentTab.feed = [];
    },
    deleteTabFromState: (state, { payload }: PayloadAction<string>) => {
      state.tabs = state.tabs.filter(tab => tab.id !== payload)
      state.initialTabs = state.tabs.filter(tab => tab.id !== payload)
      state.deleteOrUpdateTabId = null;
      state.isSuccessDelete = false;
    },
    clearAllFiltersForTab: (state, { payload }: PayloadAction<{tabId: string}>) => {
      const tabIndex = state.tabs.findIndex(tab => tab.id === payload.tabId);
      if (tabIndex !== -1) {
        state.tabs[tabIndex].includeKeywords = [];
        state.tabs[tabIndex].excludeKeywords = [];
        state.tabs[tabIndex].daysOption = options[options.length - 1]
        state.tabs[tabIndex].excludeLeadComentedBeforeDayCount = excludeLeadsDaysOption[0]
      }
    },            
  },
  extraReducers: (builder) => {
    //getFeed
    builder.addCase(getFeed.pending, (state, action) => {
      state.isGetFeedLoading = true;
      state.currentLoadingTab = action.meta.arg.tabId   
    })
    builder.addCase(getFeed.fulfilled, (state, action) => {
      const {tabId} = action.meta.arg;
      const feed = action.payload.feed;
      const currentTab = state.tabs.find(tab => tab.id === tabId);
      currentTab.feedCount = action.payload.totalCount;
      currentTab.feed.push(...feed);
      const currentPages = state.pagesForTabs.find(page => page.tabId === tabId);

      if(currentPages) {
        currentPages.page = currentPages.page + 1;
        currentPages.hasMore = !(feed.length < 10)
      } 
      
      state.isGetFeedLoading = false;
      state.currentLoadingTab = ''
    })
    builder.addCase(getFeed.rejected, (state, action) => {
      state.isGetFeedError = true;
      state.isGetFeedLoading = false;
      state.currentLoadingTab = ''
    })
    //getFeedGeneral
    builder.addCase(getFeedGeneralTab.pending, (state, action) => {
      state.isGetFeedLoading = true;
      state.currentLoadingTab = 'general'   
    })
    builder.addCase(getFeedGeneralTab.fulfilled, (state, action) => {
      const feed = action.payload.feed;
      const currentTab = state.tabs.find(tab => tab.id === 'general');
      currentTab.feedCount = action.payload.totalCount;
      currentTab.feed.push(...feed);
      const currentPages = state.pagesForTabs.find(page => page.tabId === 'general');

      if(currentPages) {
        currentPages.page = currentPages.page + 1;
        currentPages.hasMore = !(feed.length < 10)
      } 
      
      state.isGetFeedLoading = false;
      state.currentLoadingTab = ''
    })
    builder.addCase(getFeedGeneralTab.rejected, (state, action) => {
      state.isGetFeedError = true;
      state.isGetFeedLoading = false;
      state.currentLoadingTab = ''
    })
    //getAllTabs
    builder.addCase(getAllTabs.pending, (state, action) => {
      state.isAllTabsLoading = true;
    })
    builder.addCase(getAllTabs.fulfilled, (state, action) => {
      const pagesForPush: {tabId: string, page: number, hasMore: boolean}[] = []
      const tabs = action.payload.map(tab => {
        pagesForPush.push({ tabId: tab.id, page: 0, hasMore: tab.feed.length >= 10 })
        return {
          ...tab,
          disabled: false,
          isPossibleDelete: true,
        }        
      })
      state.tabs = [initialTab, ...tabs]
      state.initialTabs = [initialTab, ...tabs]
      state.pagesForTabs = [initialPageForTab, ...pagesForPush]
      state.isAllTabsLoading = false; 
    })
    builder.addCase(getAllTabs.rejected, (state, action) => {
      state.isAllTabsLoading = false;
      state.isAllTabsError = true;
    })
    //createNewTab
    builder.addCase(createNewTab.pending, (state) => {
      state.isSavingTab = true;
    })
    builder.addCase(createNewTab.fulfilled, (state, action) => {
      state.isSavingTab = false;
      const newTab = {
        ...action.payload,
        disabled: false,
        isPossibleDelete: true,
      }
      state.tabs.push(newTab)
      state.initialTabs.push(newTab)

      const hasMore = !(action.payload.feed.length < 10)

      const pages = { tabId: action.payload.id, page: 0, hasMore }
      state.pagesForTabs.push(pages)

      const generalTab = state.tabs.find(tab => tab.id === 'general')
      generalTab.daysOption = options[options.length - 1];
      generalTab.excludeKeywords = [];
      generalTab.includeKeywords = [];
      generalTab.excludeLeadComentedBeforeDayCount = excludeLeadsDaysOption[0]
    })
    builder.addCase(createNewTab.rejected, (state, action) => {
      state.isSavingTab = false;
    })
    //deleteTab
    builder.addCase(deleteTab.pending, (state, action) => {
      state.isDeletingtab = true;
      state.deleteOrUpdateTabId = action.meta.arg.tabId
    })
    builder.addCase(deleteTab.fulfilled, (state, action) => {
      state.isSuccessDelete = true;
      state.isDeletingtab = false;
    })
    builder.addCase(deleteTab.rejected, (state, action) => {
      state.isDeletingtab = false;
      state.deleteOrUpdateTabId = null;
    })
    //updateTab
    builder.addCase(updateTab.pending, (state) => {
      state.isUpdatingTab = true;
    })
    builder.addCase(updateTab.fulfilled, (state, action) => {
      state.isUpdatingTab = false;
      const {id} = action.payload;
      const tabIndex = state.tabs.findIndex(tab => tab.id === id);

      if (tabIndex !== -1) {
        state.tabs[tabIndex] = action.payload;
        state.initialTabs[tabIndex] = action.payload;
      }
      const currentPages = state.pagesForTabs.find(page => page.tabId === id);
      if(currentPages) {
        currentPages.hasMore = !(action.payload.feed.length < 10);
        currentPages.page = 0;
      } 
    })
    builder.addCase(updateTab.rejected, (state, action) => {
      state.isUpdatingTab = false;
    })
    //updateTitleTab
    builder.addCase(updateTitleTab.pending, (state, action) => {
      state.deleteOrUpdateTabId = action.meta.arg.tabId
      state.isUpdatingTitleTab = true;

    })
    builder.addCase(updateTitleTab.fulfilled, (state, action) => {
      state.isUpdatingTitleTab = false;
      state.deleteOrUpdateTabId = null;
      const tabIndex = state.tabs.findIndex(tab => tab.id === action.meta.arg.tabId);
      if (tabIndex !== -1) {
        state.tabs[tabIndex].title = action.payload.title;
        state.initialTabs[tabIndex].title = action.payload.title;
      }
    })
    builder.addCase(updateTitleTab.rejected, (state, action) => {
      state.isUpdatingTitleTab = false;
      state.deleteOrUpdateTabId = null;
    })   
    //getPromptList
    builder.addCase(getPromptList.fulfilled, (state, action) => {
      state.promptList = action.payload;
    })
    //generateMessage
    builder.addCase(generateMessage.pending, (state, action) => {
      state.postIdGeneratedMessage.push(action.meta.arg.feedId)
    })
    builder.addCase(generateMessage.fulfilled, (state, action) => {
      const {feedId} = action.meta.arg
      state.postIdGeneratedMessage = state.postIdGeneratedMessage.filter(id => id !== feedId)
      const currentMessage = state.generatedMessages.find(message => (
        message.postId === feedId
        ))
        
      if(currentMessage) {
        const newMessages = state.generatedMessages.filter(message => message.postId !== feedId)
        newMessages.push({text: action.payload.text, postId: action.meta.arg.feedId})
        state.generatedMessages = newMessages;
        return;
      }

      state.generatedMessages.push({text: action.payload.text, postId: action.meta.arg.feedId});
    })
    builder.addCase(generateMessage.rejected, (state, action) => {
      const {feedId} = action.meta.arg
      state.postIdGeneratedMessage = state.postIdGeneratedMessage.filter(id => id !== feedId)
    })
    //Send message
    builder.addCase(sendMessage.pending, (state, action) => {
      state.postIdsSendingMessage.push(action.meta.arg.id)
    })   
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      const {id, message} = action.meta.arg
      state.tabs.forEach(tab => {
        tab.feed = tab.feed.map(post => (post.id === id 
          ? { ...post, isHidden: true, sendedMessage: message } 
          : post));
      })
      state.postIdsSendingMessage = state.postIdsSendingMessage.filter(currentId => currentId !== id)
    })
    builder.addCase(sendMessage.rejected, (state, action) => {
      const {id} = action.meta.arg
      state.postIdsSendingMessage = state.postIdsSendingMessage.filter(currentId => currentId !== id)
    })
    //Delete post
    builder.addCase(deletePost.pending, (state, action) => {
      state.postIdsDeliting.push(action.meta.arg.postId)
    })   
    builder.addCase(deletePost.fulfilled, (state, action) => {
      const {postId} = action.meta.arg
      state.tabs.forEach(tab => {
        const postOnTab = tab.feed.find(post => post.id !== postId)
        if(postOnTab) {
          tab.feed = tab.feed.filter(post => post.id !== postId);
          tab.feedCount -= 1;
                 
        }
      })
      state.postIdsDeliting = state.postIdsDeliting.filter(currentId => currentId !== postId)
    })
    builder.addCase(deletePost.rejected, (state, action) => {
      const {postId} = action.meta.arg
      state.postIdsDeliting = state.postIdsDeliting.filter(currentId => currentId !== postId)
    }) 
  }    
});

export const newFeedActions = newFeedSlice.actions;
export const newFeedReducer = newFeedSlice.reducer;
