import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { sidebarSliceActions } from '../../slices/sidebarSlice/sidebarSlice';
import { checkValidCookie, getSideBarData } from '../../slices/sidebarSlice/thunks';

const rootActions = {
  ...sidebarSliceActions,
  checkValidCookie,
  getSideBarData
};

export const useSidebarActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};