import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { LoaderSkeleton } from './LoaderSkeleton';
import { TableBillsHeader } from './TableBillsHeader';
import { TableBillsBody } from './TableBillsBody';
import { useAccountDetailsData } from '../../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { NoDataAtSearch } from '../../../../components/noDataForSearch/NoDataForSearch';
import { TablePagination } from '@mui/material';
import { useAccountDetailsActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { getUserInformationFromlocalStorage } from '../../../../utils/general/get_set_user_information';

export const TableBills = () => {
  const { invoices, isGetInvoicesRequest, invoicePage, totalInvoicesCount } = useAccountDetailsData();
  const { setInvoicePage, getInvoicesHistory } = useAccountDetailsActions();
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);
  const { email } = getUserInformationFromlocalStorage()

  const handleChangePage = (newPage: number) => {
    setInvoicePage(newPage);
  };

  useEffect(() => {
    const take = 10;
    const skip = take * invoicePage
    getInvoicesHistory({
      take,
      skip,
      sortByDate: 'desc',
      customerEmail: email,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicePage])

  if (invoices?.length === 0 && !isGetInvoicesRequest) {
    return <NoDataAtSearch />
  }

  return (
    <Box >
      <Paper sx={{ boxShadow: 'unset' }}>
        <TableContainer ref={tableContainerRef} sx={{ borderRadius: 2 }} >
          <Table
            ref={tableRef}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableBillsHeader />
            {invoices?.length === 0 && isGetInvoicesRequest && <LoaderSkeleton />}
            <TableBillsBody />
          </Table>

        </TableContainer>
        <TablePagination
          component="div"
          count={totalInvoicesCount || 0}
          rowsPerPage={10}
          page={invoicePage}
          onPageChange={(event, newPage) => handleChangePage(newPage)}
          showFirstButton
          showLastButton
          rowsPerPageOptions={[]}
        />
      </Paper>
    </Box >
  );
}
