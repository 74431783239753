import React, { useEffect } from 'react';
import styles from "../../RunAutomation.module.scss";
import { Box, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAutomationData } from '../../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import ChatIcon from '@mui/icons-material/Chat';

export const PromptContentStepOne = () => {
  const { prompts, runAutomationPromptId } = useAutomationData();
  const { setRunAutomationPromptId, getAllPrompts } = useAutomationActions();
  const userPrompts = prompts.filter(prompt => prompt.show)

  const handleChangePrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRunAutomationPromptId(Number((event.target as HTMLInputElement).value))
  };

  useEffect(() => {
    // if (!prompts.length) {
    getAllPrompts();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box className={styles.promptContent}>
      <Box className={styles.promptContent__title}>
        <ChatIcon />
        My Prompts
      </Box>
      <Divider sx={{ width: '100%', marginBottom: '16px' }} />
      <Box className={styles.promptContent__prompts}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={runAutomationPromptId}
          onChange={handleChangePrompt}
        >
          {userPrompts.map(prompt => (
            <Box key={prompt.id} className={styles.promptContent__prompt}>
              <FormControlLabel
                value={prompt.id}
                control={<Radio sx={{
                  color: 'rgba(221, 223, 227, 1)', // Custom color for radio button
                  '&.Mui-checked': {
                    color: 'rgba(59, 36, 112, 1)', // Color when checked
                  },
                }} />}
                label={prompt.name}
                className={styles.promptContent__label}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'Inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: 'rgba(59, 36, 112, 1)',
                  },
                }}
              />
              <Box className={styles.promptContent__promptText}>
                {`${prompt.prompt.slice(0, 150)}...`}
              </Box>
              <Divider sx={{ width: '100%', marginTop: '12px' }} />
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </Box >
  );
};