import UserInformation from "../../../types/entities/user_information";
import { PaymentFlow } from "../../../types/PPG/paymentFlow.enum";
import { SubscriptionWithPaymentFlowI } from "../../../types/PPG/SubscriptionI";

export const getActiveSubscriptionWithPaymentFlow = (user: UserInformation): SubscriptionWithPaymentFlowI | null => {
  const activeSubscription = user.subscriptions?.find(sub => sub.isActive) || null;

  if(activeSubscription) {
    switch(user.paymentFlow) {
      case PaymentFlow.INVOICE:
        return {...activeSubscription, paymentFlow: PaymentFlow.INVOICE};
      case PaymentFlow.PPG:
        return {...activeSubscription, paymentFlow: PaymentFlow.PPG};
      default: return null;
    }
  }

  return null;
}
