import UserInformation from "../../types/entities/user_information";
import Cookies from 'universal-cookie';
import { PaymentFlow } from "../../types/PPG/paymentFlow.enum";

export default function setUserInformationTolocalStorage(data: UserInformation, needToSaveToken = true) {
    // const object = { token: data.access_token, timestamp: new Date().getTime() }
    // localStorage.setItem("token", JSON.stringify(object));

    if (needToSaveToken) {
        localStorage.setItem('token', data.access_token);
    }
    localStorage.setItem('userId', String(data.id));
    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('lastName', data.lastName);
    localStorage.setItem('title', data.title);
    localStorage.setItem('email', data.email);
    localStorage.setItem('profileImg', data.profileImg);
    localStorage.setItem('slots', data.slots.toString());
    localStorage.setItem('comments', data.comments.toString());
    localStorage.setItem('slotsLeft', data.slotsLeft.toString());
    localStorage.setItem('commentsLeft', data.commentsLeft.toString());
    localStorage.setItem('engagement', data?.engagement ? data.engagement.toString() : '0');
    localStorage.setItem('leadsActivity', data?.leadsActivity ? data.leadsActivity.toString() : '0');
    localStorage.setItem('viewedProfile', data?.viewedProfile ? data.viewedProfile.toString() : '0');
    localStorage.setItem('linkedInConnections', data?.linkedInConnections ? data.linkedInConnections.toString() : '0');
    localStorage.setItem('roles', data.roles.toString());
    localStorage.setItem('organizationName', data.organizationName);
    localStorage.setItem('organizationRole', data.organizationRole);
    localStorage.setItem('organizationId', data.organizationId)
    localStorage.setItem('paymentFlow', data.paymentFlow)
    setAuthCookies(data.uidd, data.access_token)    
}

export function getUserInformationFromlocalStorage(): UserInformation {
    const userInformation: UserInformation = {
        id: Number(localStorage.getItem('userId')),
        access_token: localStorage.getItem('token'),
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        title: localStorage.getItem('title'),
        email: localStorage.getItem('email'),
        profileImg: localStorage.getItem('profileImg'),
        slots: parseInt(localStorage.getItem('slots')),
        comments: parseInt(localStorage.getItem('comments')),
        slotsLeft: parseInt(localStorage.getItem('slotsLeft')),
        commentsLeft: parseInt(localStorage.getItem('commentsLeft')),
        roles: localStorage.getItem('roles'),
        engagement: localStorage.getItem('engagement'),
        leadsActivity: localStorage.getItem('leadsActivity'),
        viewedProfile: localStorage.getItem('viewedProfile'),
        linkedInConnections: localStorage.getItem('linkedInConnections'),
        organizationName: localStorage.getItem('organizationName'),
        organizationRole: localStorage.getItem('organizationRole'),
        organizationId: localStorage.getItem('organizationId'),
        paymentFlow: localStorage.getItem('paymentFlow') as PaymentFlow
    }
    return userInformation;
}

export function cleanUpAllStorage() {
    const storageLength = localStorage.length;
    for (let i = storageLength - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        localStorage.removeItem(key);
    }
}

export function checkUserRole(role: string): boolean {
    const roles = localStorage.getItem('roles');
    if(roles) {
    const rolesArray = roles.split(',');
    return rolesArray.includes(role);
    } else {
        return false
    }
}

export const setAuthCookies = (ht: string, tokenHt: string) => {
    const cookies = new Cookies();
    const oneWeekInMilliseconds = 365.25 * 24 * 60 * 60 * 1000;
    const expirationDate = new Date(Date.now() + oneWeekInMilliseconds);
    cookies.set('ht', ht, { 
        path: '/',
        expires: expirationDate,
        sameSite: 'none',
        secure: true
    });
    cookies.set('token-ht', tokenHt, { 
        path: '/',
        expires: expirationDate,
        sameSite: 'none',
        secure: true
    });
}

export const deleteAuthCookies = () => {
    const cookies = new Cookies();
    cookies.remove('ht', { path: '/' });
    cookies.remove('token-ht', { path: '/' });
}

export function setPaymentFlowToLocalStorage(paymentFlow: PaymentFlow) {
    localStorage.setItem('paymentFlow', paymentFlow); 
}

export function setRolesToLocalStorage(roles: string | string[]) {
    localStorage.setItem('roles', roles.toString()); 
}